// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-api-keys-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/api-keys.mdx" /* webpackChunkName: "component---docs-api-keys-mdx" */),
  "component---docs-dashboard-overview-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/dashboard-overview.mdx" /* webpackChunkName: "component---docs-dashboard-overview-mdx" */),
  "component---docs-basic-flow-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/basic-flow.mdx" /* webpackChunkName: "component---docs-basic-flow-mdx" */),
  "component---docs-introduction-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/introduction.mdx" /* webpackChunkName: "component---docs-introduction-mdx" */),
  "component---docs-firewall-settings-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/firewall-settings.mdx" /* webpackChunkName: "component---docs-firewall-settings-mdx" */),
  "component---docs-users-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/users.mdx" /* webpackChunkName: "component---docs-users-mdx" */),
  "component---docs-configure-notification-groups-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/configure/notification-groups.mdx" /* webpackChunkName: "component---docs-configure-notification-groups-mdx" */),
  "component---docs-configure-account-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/configure/account.mdx" /* webpackChunkName: "component---docs-configure-account-mdx" */),
  "component---docs-configure-policy-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/configure/policy.mdx" /* webpackChunkName: "component---docs-configure-policy-mdx" */),
  "component---docs-configure-setup-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/configure/setup.mdx" /* webpackChunkName: "component---docs-configure-setup-mdx" */),
  "component---docs-usage-api-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/api.mdx" /* webpackChunkName: "component---docs-usage-api-mdx" */),
  "component---docs-configure-tags-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/configure/tags.mdx" /* webpackChunkName: "component---docs-configure-tags-mdx" */),
  "component---docs-usage-azure-native-vms-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/azure-native-vms.mdx" /* webpackChunkName: "component---docs-usage-azure-native-vms-mdx" */),
  "component---docs-usage-disaster-recovery-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/disaster-recovery.mdx" /* webpackChunkName: "component---docs-usage-disaster-recovery-mdx" */),
  "component---docs-usage-operations-on-resources-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/operations-on-resources.mdx" /* webpackChunkName: "component---docs-usage-operations-on-resources-mdx" */),
  "component---docs-usage-restore-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/restore.mdx" /* webpackChunkName: "component---docs-usage-restore-mdx" */),
  "component---docs-usage-snapshot-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/usage/snapshot.mdx" /* webpackChunkName: "component---docs-usage-snapshot-mdx" */),
  "component---docs-technical-documentation-backup-process-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/technical-documentation/backup-process.mdx" /* webpackChunkName: "component---docs-technical-documentation-backup-process-mdx" */),
  "component---docs-technical-documentation-hooks-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/technical-documentation/hooks.mdx" /* webpackChunkName: "component---docs-technical-documentation-hooks-mdx" */),
  "component---docs-technical-documentation-encryption-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/technical-documentation/encryption.mdx" /* webpackChunkName: "component---docs-technical-documentation-encryption-mdx" */),
  "component---docs-technical-documentation-scanning-mdx": () => import("/codebuild/output/src3100744155/src/autobackup-docs/docs/technical-documentation/scanning.mdx" /* webpackChunkName: "component---docs-technical-documentation-scanning-mdx" */)
}

